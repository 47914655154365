
import RoleService from '../services/RoleService';
import UserService from '../services/UserService';
import LocalStorageService from '../services/LocalStorageService';

import { orderBy } from 'lodash';
import { defineComponent } from 'vue';

interface ComponentData {
  roleService: RoleService;
  userService: UserService;
  localStorageService: LocalStorageService;

  showDescendingUserName: boolean;
  showDescendingEmail: boolean;
  showDescendingRole: boolean;

  defaultSortedUserName: boolean;
  defaultSortedEmail: boolean;
  defaultSortedRole: boolean;

  users: any[];
  allUsers: any[];
  filteredUsers: any[];
  roles: any[];
  selectedUser: any;
  changes: any[];
  showAuditTable: boolean;
  loading: boolean;
  roleflag: boolean;
  unSelectedUser: any;
  isSuperadminLoc: boolean;
  nameDescSort: boolean;
  emailDescSort: boolean;
  roleDescSort: boolean;
  notificationDescSort: boolean;
  noData: boolean;
  locs: any[];
  searchFieldValue: string;
  sortingValue: string;
  selectedRoleId: string;
  roleOptions: { value: any; text: string }[];
  locationOptions: { value: any; text: string }[];
  selectedLocationId: string;
  fields: {
    username: { label: string };
    email: { label: string };
    role: { label: string };
    receiveEmails: { label: string };
  };
  nameActive: boolean;
  roleActive: boolean;
  emailActive: boolean;
  params: {
    locationId: string;
    expand: string;
    orderByField: string;
    orderBy: string;
  };

  confirmMessage: string;
  showConfirm: boolean;
  actionType: 'notification' | 'role';
  changeNotificationEmail: boolean;
  changeUserRole: boolean;
  targetUser: any;
}

export default defineComponent({
  name: 'Users',
  data() {
    const data: ComponentData = {
      roleService: undefined,
      userService: undefined,
      localStorageService: undefined,

      showDescendingUserName: true,
      showDescendingEmail: false,
      showDescendingRole: false,

      defaultSortedUserName: false,
      defaultSortedEmail: false,
      defaultSortedRole: false,

      users: [],
      allUsers: [],
      filteredUsers: [],
      roles: [],
      selectedUser: {},
      changes: [],
      showAuditTable: false,
      loading: false,
      roleflag: false,
      unSelectedUser: undefined,
      isSuperadminLoc: false,
      nameDescSort: false,
      emailDescSort: false,
      roleDescSort: false,
      notificationDescSort: false,
      noData: true,
      locs: [],
      searchFieldValue: '',
      sortingValue: 'ASC',
      selectedRoleId: null,
      roleOptions: [{ value: {}, text: 'Please select a role' }],
      locationOptions: [{ value: null, text: 'Please select a location' }],
      selectedLocationId: undefined,
      fields: {
        username: { label: 'Username' },
        email: { label: 'Email' },
        role: { label: 'Role' },
        receiveEmails: { label: 'Send system notifications?' },
      },
      nameActive: false,
      roleActive: false,
      emailActive: false,
      params: {
        locationId: null,
        expand: 'roles',
        orderByField: 'username',
        orderBy: 'ASC',
      },

      confirmMessage: '',
      showConfirm: false,
      actionType: undefined,
      changeNotificationEmail: false,
      changeUserRole: false,
      targetUser: undefined,
    };
    return data;
  },
  created() {
    this.roleService = new RoleService(new LocalStorageService());
    this.userService = new UserService();
    this.localStorageService = new LocalStorageService();
  },
  async mounted() {
    this.loading = true;
    this.roleflag = false;
    this.roles = this.roleService.allRoles();
    this.selectedLocationId = this.localStorageService.getItem(
      'primary_location_id',
    );
    await this.$store.dispatch('getLocationList');
    this.locs = this.$store.getters.allLocationList;
    this.setLocationOptions();
    this.loading = false;
    /**
     * Lets set the selected id if exists in state
     */
    // this.selectedLocationId = this.$store.getters.getCurrentLocation
    /**
     * If location is already present call change location
     */
    if (this.selectedLocationId) {
      await this.changeLocation();
    }
    this.sortCol('name');
  },
  methods: {
    async changeLocation() {
      // if (this.roleService.isSuperadmin || this.roleService.isSupport) {
      //   this.$store.dispatch('setCurrentLocation', this.selectedLocationId)
      // }
      this.loading = true;
      this.users = [];
      this.unSelectedUser = [];
      this.isSuperadminLoc = true;
      this.roleOptions = [];
      if (this.selectedLocationId !== null) {
        this.localStorageService.store(
          'primary_location_id',
          this.selectedLocationId,
        );
        this.params = {
          ...this.params,
          locationId: this.selectedLocationId,
        };
        await this.userService
          .getUsersList(this.params)
          .then((res) => {
            this.users = res.data.users;
            this.allUsers = res.data.users;
            if (this.users.length) {
              this.noData = false;
            }
            this.unSelectedUser = JSON.parse(JSON.stringify(res.data.users));
            this.setRoleOptions();
            const locationOfSuperadmin =
              this.localStorageService.getItem('locations');
            if (
              locationOfSuperadmin &&
              locationOfSuperadmin.slice(2, -2) === this.selectedLocationId &&
              this.roleService.isSuperadmin
            ) {
              this.isSuperadminLoc = false;
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    sortRole(a: any, b: any, isAsc: boolean) {
      const roleNameA = a.role ? a.role.name : null;
      const roleNameB = b.role ? b.role.name : null;

      if (roleNameB === null && roleNameA === null) return 0;

      if (isAsc) {
        if (roleNameA < roleNameB) return -1;
        if (roleNameA > roleNameB) return 1;
        if (roleNameB === null) return -1;
        if (roleNameA === null) return 1;
        else return 0;
      } else {
        if (roleNameA > roleNameB) return -1;
        if (roleNameA < roleNameB) return 1;
        if (roleNameB === null) return 1;
        if (roleNameA === null) return -1;
        else return 0;
      }
    },

    setRoleOptions() {
      this.roleOptions = this.roles.map((role: any) => {
        return { value: role, text: role.name };
      });

      this.roleOptions = this.roleOptions.filter(function (el) {
        return el != null;
      });
    },

    changeRole() {
      if (this.changeUserRole) {
        this.setRole(this.targetUser.id, this.targetUser.role);
      } else {
        this.users = JSON.parse(JSON.stringify(this.unSelectedUser));
      }
    },

    async setRole(user: any, role: any) {
      const setRoleResponse = await this.roleService.setUserRole(user, role);
      if (setRoleResponse.status === 200) {
        this.getUsers();
      }
      this.showAuditTable = false;
    },

    getUsers() {
      this.userService.getUsersList(this.params).then((res) => {
        if (res.data.users) {
          this.users = res.data.users;
          if (this.users.length) {
            this.noData = false;
          }
          this.unSelectedUser = JSON.parse(JSON.stringify(res.data.users));
        }
      });
      if (!this.roleService.isSuperadmin) {
        for (let i = 0; i < this.roles.length; i++) {
          if (this.roles[i].name === 'support') {
            delete this.roles[i];
          }
        }
      }
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i] !== undefined) {
          if (
            this.roles[i].name === 'superadmin' ||
            this.roles[i].name === 'patient'
          ) {
            delete this.roles[i];
          }
        }
      }
    },

    openConfirmation(user: any, type: 'role' | 'notification') {
      this.targetUser = user;
      this.actionType = type;
      if (type === 'notification') {
        this.confirmMessage = `Are you sure, Do you want to change notification settings?`;
      } else {
        this.confirmMessage = `Do you want to change Role to ${user.role.name}`;
      }
      this.showConfirm = true;
    },

    changeNotification() {
      if (this.changeNotificationEmail) {
        this.setReceiveEmails(
          this.targetUser.id,
          this.targetUser.receiveEmails,
        );
      } else {
        const idx = this.users.findIndex(
          (user) => user.id === this.targetUser.id,
        );
        this.users[idx].receiveEmails = !this.targetUser.receiveEmails;
        this.$forceUpdate();
      }
    },

    confirmAction() {
      if (this.actionType === 'notification') {
        this.changeNotificationEmail = true;
        this.changeNotification();
      } else {
        this.changeUserRole = true;
        this.changeRole();
      }
      this.showConfirm = false;
    },

    cancelAction() {
      if (this.actionType === 'notification') {
        this.changeNotificationEmail = false;
        this.changeNotification();
      } else {
        this.changeUserRole = false;
        this.changeRole();
      }
      this.showConfirm = false;
    },

    async setReceiveEmails(userId: string, event: any) {
      await this.userService.setReceiveEmails(userId, event);
      this.showAuditTable = false;
    },

    async toggleShowAuditTable() {
      this.changes = await this.userService.getAuditTrailForUser();
      this.showAuditTable = !this.showAuditTable;
    },

    getAuditButtonVerb() {
      if (this.showAuditTable) return 'Hide';
      else return 'Show';
    },

    setLocationOptions() {
      this.locationOptions = this.locationOptions.concat(
        this.locs.map((location: any) => {
          return { value: location.id, text: location.name };
        }),
      );
    },

    handleSearchField(event: any, para: string, para2: string) {
      this.searchFieldValue = event.target.value;
      if (this.searchFieldValue === '') {
        this.users = this.allUsers;
      } else {
        if (para === 'username') {
          this.filteredUsers = this.allUsers.filter((u: any) => {
            if (u.username.toLowerCase().includes(this.searchFieldValue)) {
              return u;
            }
          });
        } else if (para === 'email') {
          this.filteredUsers = this.allUsers.filter((u: any) => {
            if (u.email.toLowerCase().includes(this.searchFieldValue)) {
              return u;
            }
          });
        } else if (para === 'role') {
          this.filteredUsers = this.allUsers.filter((u: any) => {
            let value = undefined;
            try {
              value = u.role?.name ?? undefined;
            } catch (err) {
              console.error('error', err);
            }
            if (value != undefined) {
              if (value.toLowerCase().includes(this.searchFieldValue)) {
                return u;
              }
            }
          });
        }
        this.users = this.filteredUsers;
      }
    },
    sortCol(event: any) {
      // API doesn't support sorting
      const isDesc = event.includes('Desc');
      if (event.includes('name')) {
        this.defaultSortedUserName = false;
        this.defaultSortedEmail = true;
        this.defaultSortedRole = true;
        this.nameDescSort = isDesc;
        this.nameActive = true;
        this.roleActive = false;
        this.emailActive = false;
        if (this.nameDescSort) {
          this.showDescendingUserName = true;
          this.showDescendingEmail = false;
          this.showDescendingRole = false;
        }

        this.params = {
          ...this.params,
          orderBy: isDesc ? 'DESC' : 'ASC',
          orderByField: 'username',
        };
        this.users = orderBy(this.users, (u: any) => u.username, [
          isDesc ? 'desc' : 'asc',
        ]);
      } else if (event.includes('email')) {
        this.defaultSortedUserName = true;
        this.defaultSortedEmail = false;
        this.defaultSortedRole = true;
        this.emailDescSort = isDesc;
        this.nameActive = false;
        this.roleActive = false;
        this.emailActive = true;
        if (this.emailDescSort) {
          this.showDescendingUserName = false;
          this.showDescendingEmail = true;
          this.showDescendingRole = false;
        }

        this.params = {
          ...this.params,
          orderBy: isDesc ? 'DESC' : 'ASC',
          orderByField: 'email',
        };
        this.users = orderBy(this.users, (u: any) => u.email, [
          isDesc ? 'desc' : 'asc',
        ]);
      } else if (event.includes('role')) {
        this.defaultSortedUserName = true;
        this.defaultSortedEmail = true;
        this.defaultSortedRole = false;
        this.roleDescSort = isDesc;
        this.nameActive = false;
        this.roleActive = true;
        this.emailActive = false;
        if (this.roleDescSort) {
          this.showDescendingUserName = false;
          this.showDescendingEmail = false;
          this.showDescendingRole = true;
        }
        this.params = {
          ...this.params,
          orderBy: isDesc ? 'DESC' : 'ASC',
          orderByField: 'role',
        };
        this.users = orderBy(
          this.users,
          (u: any) => {
            return u.role && u.role.length ? u.role.name : u.role;
          },
          [isDesc ? 'desc' : 'asc'],
        );
      }
    },
  },
});
